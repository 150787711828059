<template>
  <div class="main">
    <CommonHeader/>
    <div class="title_section">
      ロボット実行履歴
    </div>
    <div class="search_section">
      <div>
        <el-input placeholder="キーワード" class="input-with-select" style="width: 480px;" type="primary" v-model="searchCond.robotName">
          <el-select slot="prepend" placeholder="検索項目" style="width: 120px;" v-model="searchCond.keyType">
            <el-option label="ロボット名" value="name"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" type="primary" @click="handleSearch" :clearable="true"></el-button>
        </el-input>
      </div>
      <div>
      </div>
    </div>
    <div class="data_table">
      <el-table
      :data="taskList" width="100%">
        <el-table-column
          label="ID"
          width="80px">
          <template slot-scope="scope">
            <span>
              {{ scope.row.id }}
            </span>
           </template>
        </el-table-column>
        <el-table-column
          label="ファイル"
          width="160px">
          <template slot-scope="scope">
            <span v-if="scope.row.fileName">
              <a href="scope.row.fileUrl">{{ scope.row.fileName }}</a>
            </span>
           </template>
        </el-table-column>
        <el-table-column
          label="ロボット名">
          <template slot-scope="scope">
            <span style="font-weight: bold;font-size: 16px;">
              {{ scope.row.robotName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="チャネル">
          <template slot-scope="scope">
            <span v-if="scope.row.channel==1">
              メール
            </span>
            <span v-if="scope.row.channel==2">
              ドライブ
            </span>
            <span v-if="scope.row.channel==3">
              スキャナ
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="開始時間"
          width="160px">
          <template slot-scope="scope">
          <span>
            {{ scope.row.startTime }}
          </span>
          </template>
        </el-table-column>
        <el-table-column
          label="終了時間"
          width="160px">
          <template slot-scope="scope">
          <span>
            {{ scope.row.endTime }}
          </span>
          </template>
        </el-table-column>
        <el-table-column
          label="状態">
          <template slot-scope="scope">
            <span v-if="scope.row.status==10">
              <el-tag type="info" size="mini" >実行中</el-tag>
            </span>
            <span v-if="scope.row.status==15">
              <el-tag type="" size="mini" >成功</el-tag>
            </span>
            <span v-if="scope.row.status==20">
              <el-tag type="success" size="mini" >失敗</el-tag>
            </span>
            <span v-if="scope.row.status==30">
              <el-tag type="warning" size="mini" >中止</el-tag>
            </span>
          </template>
        </el-table-column>
        <template slot="empty">
          <el-empty description="該当データがありません" style="margin:auto"></el-empty>
        </template>
      </el-table>      
    </div>
    <PageBar style="margin-top: 8px;">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalCount"
        @current-change="handleCurrentChange"
        :current-page.sync="searchCond.pageIndex"
        :page-size="searchCond.pageSize"
      >
      </el-pagination>
    </PageBar>
    
    <div style="flex:1"></div>
    <CommonFooter />
    
  </div>
</template>
<script>
import CommonHeader from "@/components/common/CommonHeader.vue";
import CommonFooter from "@/components/common/CommonFooter.vue";

import { isValidResponse } from "@/utils/commonUtils";
import { ROUTER_PATH } from "@/constants/common.js";
import { mapState } from "vuex";
import { listTask } from "@/api/kintone";

export default {
  name: "TaskList",
  components: {
    CommonHeader,
    CommonFooter
  },
  data: () => {
    return {
      searchCond:{
        robotName:"",
        pageIndex:1,
        pageSize:10
      },
      totalCount:0,
      filterStatus: [],
      taskList:[],
    };
  },
  mounted() {
    this.handleSearch();
  },
  created: function () {
  },
  computed: {
    ...mapState(["accountInfo"]),
  },
  methods: {
    handleSearch() {
      listTask(
        this.searchCond
      ).then(response => {
        this.taskList = response.data.records;
        this.totalCount = response.data.total;
      });
    },
    handleCurrentChange(val) {
      this.searchParams = { ...this.searchParams, pageIndex: val };
      this.handleSearch();
    },
  },
};
</script>
<style scoped>
.title_section {
  display: flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-between;
  width: 1060px;
  margin-top: 32px;
  font-size: 18px;
  font-weight: bold;
}
.search_section {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
  background-color: #fff;
  width: 1024px;
  margin-top: 16px;
  padding: 18px;
  border-radius: 12px 12px 0px 0px;
}
.data_table {
  width: 1024px;
  margin-top: 4px;
  padding: 18px;
  border-radius: 0px 0px 12px 12px;
  background-color: #fff;
}

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>

